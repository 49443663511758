<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t("plans.code_settings") }} :
            {{ plan && plan.plan_name  ? plan.plan_name : '' }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12">
            <b-form-checkbox
              v-model="selectAllChecked"
              @change="checkAllCodeSettings"
              class="mr-2"
              >{{ $t("select_all") }}</b-form-checkbox
            >
            <!-- Checkbox Group -->
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="code_settings"
              :aria-describedby="ariaDescribedby"
              name="content-temp-2"
              class="row"
            >
              <b-form-checkbox
                v-for="(row, index) in code_setting_list"
                :value="row.type_type"
                :key="index"
                class="col-md-5 mt-5 mb-5"
              >
                {{ row.name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="card-footer pl-0 pr-0">
          <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t("save") }}</b-button>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import _ from "lodash";

export default {
  name: "index",
  components: {},
  data() {
    return {
      mainRoute: "/subscription/plans",
      subRoute: "/subscription/plan",
      updateCodeSetting: "/subscription/plan/assign_code_setting",

      idEdit: this.$route.params.plan_id ? this.$route.params.plan_id : "",

      code_settings: [],
      selectAllChecked: false,
      code_setting_list: [],
      validation: null,
      plan: null,
    };
  },
  mounted() {
    this.getCodeSettings();
    let promise1 = this.getData();
    // let promise2 = this.getActions();
    Promise.all([promise1]).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.plans"),route: "/subscription/plans",},{ title: this.$t("plans.code_settings") }]);
    });
    
  },
  methods: {
    save() {
      let _actions = {code_settings: []};
      _actions.code_settings = this.code_settings;
      
      ApiService.patch(`${this.updateCodeSetting}/${this.idEdit}`,_actions).then(() => {
          this.afterEdit();
        })
        .catch((error) => {
          this.validation = error.response ? error.response.data.errors : null;
          this.$errorAlert(error);
        });
    },

    afterEdit() {
      this.$router.push({ name: "plans.index" });
    },

    checkAllCodeSettings() {
      if (this.selectAllChecked) {
        this.code_settings = this.code_setting_list.map((row) => row.type_type);
      } else {
        this.code_settings = [];
      }
    },

    async getCodeSettings() {
      await ApiService.get(this.subRoute + "/code_settings").then((response) => {
          response.data.data.forEach((row) => {
            if (row.type) {
              this.code_setting_list.push(row);
            }
          });
        }
      );
    },

    async getCodeSettingFromPlan() {
      ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
        this.code_settings = response.data.data.code_settings;
      });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
          this.plan = response.data.data;
          console.log(this.plan.plan_name,this.plan);
          this.code_settings = response.data.data.code_settings ?? [];
          if (!this.code_settings) {
            this.getCodeSettingFromPlan();
          }
        }
      );
    },
  },
};
</script>
